@keyframes progress {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.circle-loading-skeleton {
  animation: progress 1.2s ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f9f9f9, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  display: inline-block;
  line-height: 1;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.rect-loading-skeleton {
  animation: progress 1.2s ease-in-out infinite;
  background-color: #eee;
  background-image: linear-gradient(90deg, #eee, #f9f9f9, #eee);
  background-size: 200px 100%;
  background-repeat: no-repeat;
  border-radius: 4px;
  display: inline-block;
  line-height: 1;
  width: 100%;
  height: 25px;
}

